@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

@font-face {
  font-family: "Redaction";
  src: url("../public/fonts/Redaction50-Italic.otf");
}

html {
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

html::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

a {
  color: #233447;
  text-decoration: underline;
  font-weight: 700;
}

.p5Canvas {
  max-width: 370px;
  max-height: 550px;
}

.signatures > div > .p5Canvas {
  max-width: 14rem;
  max-height: 330px;
}

.flip-card {
  background-color: transparent;
  width: 24rem;
  height: 24rem;
  perspective: 2500px; /* Remove this if you don't want the 3D effect */
}

@media screen and (max-width: 600px) {
  .flip-card {
    width: 15rem;
    height: 15rem;
  }

  .p5Canvas {
    max-width: 300px;
    max-height: 450px;
    background-repeat: no-repeat;
  }
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.2s;
  transform-style: preserve-3d;
}

.flip-card-inner-flipped {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 1.2s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
}

/* Style the back side */
.flip-card-back {
  transform: rotateY(180deg);
}